<template>
  <main class="contract">
    <section class="banner">
      <div class="container">
        <p class="banner__text1">Удобно</p>
        <p class="banner__text2">когда на облаке</p>
      </div>
    </section>
    <section class="info">
      <div class="container">
        <p class="info__text">
          Утверждена приказом генерального директора<br>
          ООО «СТЕЙЛ-ЮГ»<br>
          №1-21 от «13» января 2021 г.<br>
          Настоящая оферта является публичным предложением ООО «СТЕЙЛ-ЮГ» (ОГРН: 1057746300732; ИНН: 7701584501), заключить договор об оказании технологических услуг (далее — Соглашение, Оферта) в соответствии с п. 2 ст. 437 Гражданского кодекса Российской Федерации. Прохождение Регистрации является акцептом настоящей Оферты, то есть полным и безусловным принятием положений настоящей Оферты и всех приложений к ней. Принимая условия настоящей Оферты физическое лицо подтверждает, что достигло возраста 18 (восемнадцати) лет и вправе в полном объеме исполнять условия Соглашения.<br><br>

          1. Термины и определения<br>
          Виртуальная машина (далее - ВМ) — набор виртуальных ресурсов, предоставляющих Клиенту возможность устанавливать, запускать и удаленно использовать один экземпляр операционной системы с предоставлением возможности установки программ для ЭВМ. ВМ создается с помощью специализированного программного обеспечения и предоставляется с предустановленной операционной системой.<br>
          Клиент (также Владелец Проекта) — физическое лицо, индивидуальный предприниматель, либо юридическое лицо в лице своего представителя, прошедшее в установленном порядке Регистрацию и осуществляющее пользование Порталом посредством сети «Интернет».<br>
          Лицевой счёт — индивидуальный счёт Клиента в Учетной системе, который ведется Провайдером самостоятельно и служит для учета поступивших от Клиента и израсходованных Клиентом денежных средств на оплату Услуг по соответствующей Услуге.<br>
          Оплата — действия Клиента, либо представителя Клиента по внесению денежных средств на Лицевой счёт в качестве предоплаты Услуг. Сведения об Оплате отражаются в Учетной системе и в Панели управления.<br><br>

          Заказ — совокупность действий Клиента в Панели управления по выбору Услуг, параметров и конфигурации соответствующих Услуг.<br>
          Подтверждение Заказа — действия Провайдера, либо автоматизированных систем Портала по утверждению (активации) Заказа, либо отказу в утверждении (активации) Заказа.<br><br>

          Провайдер — ООО «СТЕЙЛ-ЮГ» (ОГРН 1057746300732; ИНН: 7701584501), место нахождения: 15230, Москва г, Каширское ш, дом № 3, корпус 2, строение 9, осуществляющий деятельность по разработке, адаптации и модификации программ для ЭВМ, а также услуг (работ) по установке, тестированию и сопровождению указанных программ для ЭВМ.<br>
          Регистрация — процедура прохождения Клиентом в необходимом объеме первичной идентификации на Портале в соответствии с утвержденными Провайдером правилами. Регистрация является завершенной с момента первого входа в Панель управления.<br>
          Служба Сопровождения Клиентов — структурное подразделение Провайдера, осуществляющее взаимодействие от имени Провайдера с Клиентами.<br>
          счёт для Оплаты — формируемый в автоматическом режиме в Панели управления счёт, содержащий всю необходимую информацию для Оплаты.<br>
          Тарифы — стоимость предлагаемых Провайдером услуг и продуктов, с соответствующими характеристиками с указанием их наименований, длительности оказания, единицы тарификации и иных параметров. Тарифы являются неотъемлемой частью настоящей Оферты (приложением к настоящей Оферте) и размещены в сети «Интернет» по адресу.<br><br>

          Услуга — информационно-техническая и программная услуга по предоставлению Провайдером Клиенту в течение определенного срока возможности использовать распределенные виртуальные ресурсы Провайдера, определенные родовыми признаками (объем дискового пространства - HDD/SSD, объем оперативной памяти – RAM, процессор – CPU, частота процессора – ГГц), предоставляемые Клиенту с помощью программ для ЭВМ, а также иные услуги, указанные в Тарифах.<br><br>

          Учетная запись — совокупность данных о Клиенте, имеющая собственный уникальный идентификатор и используемая для работы с Панелью Управления.<br>
          Учетная система — информационная система Провайдера, содержащая сведения о заказанных Клиентом Услугах, об Оплате, об объеме Услуг, порядке расчётов, а также о наличии задолженности по оплате Услуг.<br>
          SLA — Service Level Agreement (англ.) — соглашение об уровне обслуживания, содержащее параметры качества услуг, являющееся неотъемлемой частью настоящей Оферты (Приложением к  настоящий Оферте)<br>
        </p>

        <div class="info-panel">
          <a href="#" class="info-panel__link">
            <BaseIcon icon="download" width="20px" />
            <span>Скачать в формате.docx</span>
          </a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'

export default {
  name: 'pageContract',
  components: {
    BaseIcon
  }
}
</script>

<style lang="scss" scoped>
.contract {
  .banner {
    padding: 56px 0;
    background: #008ACB;
    color: #FFFFFF;
    &__text1 {
      font-weight: 600;
      font-size: 52px;
    }
    &__text2 {
      font-weight: 500;
      font-size: 24px;
    }
  }
  .info {
    margin-top: 60px;
    &__text {
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 1.75;
    }
    &-panel {
      margin-top: 60px;
      &__link {
        display: flex;
        align-items: center;
        color: $secondary-color;
        span {
          display: block;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
